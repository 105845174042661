<template>
  <div class="row">
    <div class="col-12 col-md-4 order-md-1">
      <div
        class="
          horizontal-nav-tabs
          d-flex
          justify-content-center justify-content-sm-center
        "
      >
        <router-link :key="$route.fullPath" to="/legal/terms">
          <span
            class="left"
            :class="{ active: $router.currentRoute.name == 'terms' }"
          >
            Terms
          </span>
        </router-link>
        <router-link to="/legal/privacy">
          <span
            class="right"
            :class="{ active: $router.currentRoute.name == 'privacy' }"
          >
            Privacy
          </span>
        </router-link>
      </div>
    </div>
    <div class="col-12 col-md-8 order-md-0">
      <template v-if="$router.currentRoute.name == 'legal'">
        <h1>Terms and Policies</h1>
        <p>
          Thank you for taking the time to learn about Tjing’s legal policies.
          It's important stuff. Here you'll find information about how we
          protect your privacy and how we handle user accounts. Some needs to be
          expressed in legal language, but we’ve done our best to offer you
          clear and simple explanations of what everything means. If you still
          have questions after reading them,
          <a href="mailto:hello@tjing.se">drop us a line</a>.
        </p>
      </template>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
};
</script>
<style lang="scss" scoped>
@import "../styles/global-styles.scss";

.horizontal-nav-tabs {
  margin-top: 0px;
}
p {
  color: $elephant;
  margin-bottom: 15px;
  @include Gilroy-Medium;
}
h1 {
  margin-top: 60px;
  display: block;
}
h5 {
  margin-bottom: 20px;
  margin-top: 30px;
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
  .horizontal-nav-tabs {
    margin-top: 10px;
  }
  h1 {
    margin-top: 35px;
    display: block;
  }
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .horizontal-nav-tabs {
    margin-top: 100px;
  }
  h1 {
    margin-top: 75px;
  }
}
</style>
